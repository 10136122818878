import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// Msal Configurations
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/common',
    clientId: 'eec0e479-eec5-44d7-8550-a525e6694748',
    postLogoutRedirectUri: window.location.origin,
      redirectUri: window.location.origin,
      validateAuthority: true,

      // After being redirected to the "redirectUri" page, should user
      // be redirected back to the Url where their login originated from?
      navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};

// Authentication Parameters
const authenticationParameters = {
  scopes: ["openid"]
//   [
//     'User.Read',
//     'https://encootech.onmicrosoft.com/tp/scope=https://graph.windows.net/User.Read'
//   ]
}

// Options    + '/auth.html'
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin  + "/auth.html"
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)