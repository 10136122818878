import React from 'react';
import '../config';
import { Table, Button, Input, Icon, Popconfirm, Select, Radio, message, Progress } from 'antd';
import Highlighter from 'react-highlight-words';
import '../css/devBuildList.css';

const { Option } = Select;
export default class DevBuildList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      version: [],
      product: [],
      branch: [],
      filteredInfo: null,
      sortedInfo: null,
      searchText: '',
      searchedColumn: '',
      Loading: true,
      types: [],
      fetching: false,
      currentPage: 1,
      pagesize: 10,
      downloadPercent: 0,
    };
  };
  componentWillMount = () => {
    this.getTypes();
    this.initTableData("Release");
  };
  initTableData(type) {
    var that = this;
    that.setState({
      Loading: true,
      filteredInfo: null,
      dataSource: []
    });
    let cp = that.state.currentPage;
    let pz = that.state.pagesize;
    fetch(global.config.baseUrl + '/api/OutApi/GetBlobList?' + "category=" + type + "&pageindex=" + cp + "&pagesize=" + pz, {
      method: 'Get',
    }).then(function (res) {
      return res.json();
    }).then(function (json) {
      let status = json["status"];
      if (status == 1) {
        message.error("数据获取失败！");
        return;
      }
      let jsonData = json["result"];
      that.setState({
        dataSource: jsonData.result,
        version: jsonData.version,
        product: jsonData.product,
        branch: jsonData.branch,
        Spinnin: false,
        Loading: false
      });
    })
  };
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState({
      pagesize: pageSize,
    });
    console.log(current, pageSize);
  };
  onChangePagination = (current) => {
    console.log(current);
    this.setState({
      currentPage: current,
    });
    // this.initTableData();
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };
  DownLoadFileOutStream = (blobName) => {
    let that = this;
    let size = 0;
    let totalLength = 0;
    let fileName = "";
    fetch(global.config.baseUrl + '/api/OutApi/DownLoadFile' + "?" + "blobName=" + blobName, {
      method: 'GET',
    }).then(response => {
      fileName = (response.headers.get('Content-Disposition') || "").split(';')[1].split('filename=')[1];

      message.loading({
        content: "正在下载  当前进度:0%",
        duration: 0,
        key: fileName
      });

      totalLength = response.headers.get('Content-Length') // 获取流总长度
      var reader = response.body.getReader();
      return new ReadableStream({
        start(controller) {
          return pump();

          function pump() {
            return reader.read().then(res => {   //res  ({ done, value }) 
              const { done, value } = res;
              if (done) {
                console.log("end");
                controller.close();
                return;
              }
              size += value.length || 0;
              // console.log((size / totalLength).toFixed(2) * 100, "size");
              var process = Math.floor((size / totalLength).toFixed(2) * 100);
              if (process == 100) {
                message.success(
                  {
                    content: "下载完成  当前进度:" + process + "%",
                    duration: 3,
                    key: fileName
                  });
              }
              else {
                message.loading({
                  content: "正在下载  当前进度:" + process + "%",
                  duration: 0,
                  key: fileName
                });
              }
              // 将下一个数据块置入流中
              controller.enqueue(value);
              return pump();
            });
          }
        }
      })
    }).then(stream => new Response(stream))
      .then(response => that.savingFile(response, fileName))
      .catch(err => console.error(err));
  };
  DownLoadFile = (blobName) => {
    fetch(global.config.baseUrl + '/api/OutApi/DownLoadFile' + "?" + "blobName=" + blobName, {
      method: 'Get',
    }).then(function (res) {
      return res.json();
    }).then(function (json) {
      let jsonData = json["result"];
      window.location.href = jsonData;
    })
  };
  savingFile = (response, fileName) => {
    const that = this;
    response.blob().then(blob => {
      if (typeof FileReader === 'undefined') {
        message.warning('您的浏览器不支持 FileReader，请升级浏览器', 3);
      }
      const reader = new FileReader();
      reader.addEventListener("loadend", function () {
        let resu = '';
        try {
          resu = JSON.parse(reader.result);
          if (resu.code == 500) {
            message.error(resu.msg, 3);
          } else if (resu.code == 401) {
            message.error(resu.msg, 3);
          }
        } catch (e) {
          //捕获错误 说明是文本字符串 
          resu = reader.result;
          downloadBlob(blob, fileName);
        }
      });
      reader.readAsText(blob);

      //下载
      function downloadBlob(blob, fileName) {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = blobUrl;
        a.target = '_blank';
        a.style.display = 'none'
        document.body.appendChild(a)
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
      }
    })
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
                </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
                </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  getTypes = () => {
    let that = this;
    that.setState({ fetching: true });
    fetch(global.config.baseUrl + '/api/OutApi/GetTypes', {
      method: 'Get',
    }).then(function (res) {
      return res.json();
    }).then(function (json) {
      let jsonData = json["result"];
      that.setState({
        types: jsonData,
        fetching: false
      });
    })

  };
  // onChange = (value) => {
  //   // console.log(`selected ${value}`);
  //   this.initTableData(value);
  // }
  onChange = (e) => {
    this.setState({
      currentPage: 1,
    })
    this.initTableData(e.target.value);
  };
  onBlur = () => {
    console.log('blur');
  };
  onFocus = () => {
    console.log('focus');
  };
  onSearch = value => {
    if (value) {
      fetch(value, data => this.setState({ data }));
    } else {
      this.setState({ data: [] });
    }
  };
  render() {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: '类别',
        dataIndex: 'buildVersion',
        key: 'buildVersion',
        filters: this.state.version,
        filteredValue: filteredInfo.buildVersion || null,
        onFilter: (value, record) => record.buildVersion.includes(value),
        sortOrder: sortedInfo.columnKey === 'buildVersion' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: '分支',
        dataIndex: 'branchName',
        key: 'branchName',
        filters: this.state.branch,
        filteredValue: filteredInfo.branchName || null,
        onFilter: (value, record) => record.branchName.includes(value),
        sortOrder: sortedInfo.columnKey === 'branchName' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: '产品',
        dataIndex: 'productName',
        key: 'productName',
        filters: this.state.product,
        filteredValue: filteredInfo.productName || null,
        onFilter: (value, record) => record.productName.includes(value),
        sortOrder: sortedInfo.columnKey === 'productName' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: '版本号',
        dataIndex: 'versionNum',
        key: 'versionNum',
        ellipsis: true,
        ...this.getColumnSearchProps('versionNum')
      },
      {
        title: '文件',
        dataIndex: 'fileName',
        key: 'fileName',
        ellipsis: true,
        // ...this.getColumnSearchProps('fileName')
      },
      {
        title: '日期',
        dataIndex: 'buildDate',
        key: 'buildDate',
        type: 'DateTime',
        onFilter: (value, record) => record.buildDate.includes(value),
        // sorter: (a, b) => a.buildDate > b.buildDate,
        // sortOrder: sortedInfo.columnKey === 'buildDate' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: '下载',
        key: 'blobName',
        dataIndex: 'blobName',
        sortOrder: sortedInfo.columnKey === 'blobName' && sortedInfo.order,
        render: (text) =>
          <Popconfirm title="确定下载?" cancelText="取消" okText="确定" onConfirm={() => this.DownLoadFile(text)}>
            <Button type="primary" shape="circle" icon="download" size="default"></Button>
          </Popconfirm>
      }
    ];
    let that = this;
    const process = <Progress type="circle" percent={that.state.downloadPercent} />
    return (
      <div>
        {
          <Radio.Group onChange={this.onChange} defaultValue="Release" buttonStyle="solid">
            {
              that.state.types.map(function (name, key) {
                return <Radio.Button value={name} className='btn'>{name}</Radio.Button>
              })
            }
          </Radio.Group>
        }
        {
          // that.state.types.map(function(name,key){
          //   return <Button key={key} className="btn" type="primary" onClick={() => that.initTableData(name)}>{name}</Button>
          // })
        }
        {/* <Select
          showSearch
          style={{ width: 240,marginBottom:20 }}
          placeholder="选择版本..."
          optionFilterProp="children"
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}
          notFoundContent={that.state.fetching ? <Spin size="small" /> : null}
          // filterOption={(input, option) =>
          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          // }
        >
          {
            that.state.types.map(function (name) {
              return <Option value={name}>{name}</Option>
            })
          }
        </Select> */}
        <Table
          loading={this.state.Loading}
          columns={columns}
          pagination={{
            pagesize: 10,
            showSizeChanger: true,
            onChange: this.onChangePagination,
            onShowSizeChange: this.onShowSizeChange,
            current: this.state.currentPage,
            defaultCurrent: 1,
            defaultPageSize: 10
          }}
          dataSource={this.state.dataSource}
          onChange={this.handleChange}
          expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{"000"}</p>,
            rowExpandable: record => record.name !== 'Not Expandable',
          }}
          scrollToFirstRowOnChange={true} />
      </div>
    );
  }
}