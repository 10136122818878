import React, { Component } from 'react';
import  {Layout ,Menu,Icon,Avatar} from 'antd';
import Logo from '../logo1.png';
import { Router, Route, Link,HashRouter } from 'react-router-dom'
import 'antd/dist/antd.min.css'
import "../css/layout.css"

const { Header, Footer, Sider, Content } = Layout;

export default class BasicLayout extends Component {
  state = {
    collapsed: false,
    defaultSelectedKeys:['1'],
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }; 
  componentDidMount = () =>{
    let path = window.location.pathname;
    if(path == '/tree'){
      this.setState({
        defaultSelectedKeys:['2']
      })
    }
    else if(path == '/process'){
      this.setState({
        defaultSelectedKeys:['3']
      })
    }
  }
  render() { 
    const {defaultSelectedKeys } = this.state;
    return (
      <Layout>
        <Sider className="siderpos" trigger={null} collapsible collapsed={this.state.collapsed}>
          <div className="logocenter">
            <img src={Logo} className="logo" />
            <div hidden={this.state.collapsed}  className="logotext">
              <span>BotTime</span> 
              <span>TestPortal</span>
            </div>
          </div>
          {/* <div hidden={this.state.collapsed}  className="logotext"><p>BotTime</p> <span>TestPortal</span></div> */}
            <Menu theme="dark" mode="inline" selectedKeys={defaultSelectedKeys}>
              <Menu.Item key="1">
                <Link to="/">
                  <Icon type="line-chart" />
                  <span>BVT</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to="/process">
                  <Icon type="dashboard" />
                  <span>Longhaul Test</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to="/tree">
                  <Icon type="download" />
                  <span>下载</span>
                </Link>
              </Menu.Item>
            </Menu>
        </Sider>
        <Layout >
          <Header style={{ background: '#fff', padding: 0 }}>
            <Icon
              className="trigger"
              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={this.toggle}
            />
          </Header>
          <Content>
            <div style={{ padding: 24, background: '#fff', minHeight: 360,margin: '3rem 2rem 5rem' }}>
              {this.props.children}
            </div>
          </Content>
          <Footer className="footerpos">Encootech Design ©2020 Created by XIAN</Footer>
        </Layout>
      </Layout>
    )
  }
}