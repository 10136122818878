import { Table, Icon, Tag, Card, Col, Row, Pagination, notification } from 'antd';
import "../css/tasktable.css";
import React from 'react';
import '../config';
// 引入 ECharts 主模块
import echarts from 'echarts/lib/echarts';
import ReactEcharts from 'echarts-for-react';

const openNotification = (msg) => {
  notification.open({
    message: '温馨提示',
    description: msg, icon: <Icon type="info-circle" style={{ color: '#108ee9' }} />,
    onClick: () => {
      // console.log('Notification Clicked!');
    },
  });
};
const columns = [
  {
    title: 'SuiteId',
    dataIndex: 'SuiteId',
    key: 'SuiteId',
  },
  {
    title: 'SuiteName',
    dataIndex: 'SuiteName',
    key: 'SuiteName',
    render: text => <a>{text}</a>,
  },
  {
    title: 'CreateTime',
    dataIndex: 'CreateTime',
    key: 'CreateTime',
  },
  // {
  //   title: 'Activity',
  //   dataIndex: 'Suits',
  //   key: 'Suits',
  // },
  {
    title: 'Total',
    dataIndex: 'Total',
    key: 'Total',
  },
  {
    title: 'Passed',
    dataIndex: 'Pass',
    key: 'Pass',
  },
  {
    title: 'Fail',
    dataIndex: 'Fail',
    key: 'Fail',
  },
  // {
  //   title: 'Log(if result = failed)',
  //   dataIndex: 'Log',
  //   key: 'Log',
  // }
];
export default class TaskTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRunId: "",
      dataSource: [],
      Loading: true,
      buildName: "",
      productName: "",
      branchName: "",
      testDate: "",
      totalCount: 0,
      current: 1,
      defaultPageSize: 10,

      series: [],
      xAxisData: [],
      legend: []
    };
    // this.componentDidMount=this.componentDidMount.bind(this);
  }
  componentDidMount = () => {
    var that = this;
    // 基于准备好的dom，初始化echarts实例
    const myChart = echarts.init(document.getElementById('main'));
    var headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
    fetch(global.config.baseUrl + '/api/TestPortalWeb/GetDgsExcuteListForEChart', headers).
      then(response => response.json()).
      then(res => {
        var jsonData = res.result;
        var seriesData = [];
        for (var i in jsonData.seriesDatas) {
          var serie = {
            name: jsonData.seriesDatas[i].name,
            type: 'line',
            data: jsonData.seriesDatas[i].data
          }
          seriesData.push(serie);
        }
        var xAxisData = [];
        for (var i in jsonData.xAxisData.data) {
          xAxisData.push(jsonData.xAxisData.data[i].node);
        }
        that.setState({
          xAxisData: xAxisData,
          series: seriesData,
          legend: jsonData.legendData
        })
        var runIdData = jsonData.xAxisData.data;
        // 绘制图表
        myChart.setOption({
          title: {
            text: 'BVT History'
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: this.state.legend
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: this.state.xAxisData
          },
          yAxis: {
            type: 'value',
            boundaryGap: false,
            // scale:true,
            axisPointer: {
              show: false
            },
            axisLabel: {
              show: true,
              interval: 'auto',
              formatter: '{value} %'
            },
            show: true
          },
          series: this.state.series
        });
        myChart.on('click', function (params) {

          that.setState({
            Loading: true
          });
          let xname = params.name;
          let serieName = params.seriesName;
          let runId = "";
          for (var i in runIdData) {
            if (runIdData[i].node === xname) {
              runId = runIdData[i].runId[serieName];
              that.setState({
                currentRunId: runId
              })
              that.loadRunDetail(runId, that.state.current, that.state.defaultPageSize);
              break;
            }
          }
        })
      });

    that.loadRunDetail("", this.state.current, this.state.defaultPageSize);
  };
  onChange = page => {
    this.setState({
      Loading: true,
      current: page,
    });
    this.loadRunDetail(this.state.currentRunId, page, this.state.defaultPageSize);
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState({
      Loading: true,
      current: current,
      defaultPageSize: pageSize
    });
    this.loadRunDetail(this.state.currentRunId, current, pageSize);
  };
  loadRunDetail = (runId, current, defaultPageSize) => {
    var that = this;
    let formData = new FormData();
    formData.append('runId', runId);
    formData.append('pageIndex', current);
    formData.append('pageSize', defaultPageSize);
    fetch(global.config.baseUrl + '/api/TestPortalWeb/GetBVTExcutedInfo', {
      method: 'post',
      body: formData,
    }).then(function (res) {
      return res.json();
    }).then(function (json) {
      let result = json["result"];
      if (result == null) {
        that.setState({
          Loading: false,
          dataSource: []
        });
        openNotification("暂无数据！");
        return;
      }
      let jsonData = JSON.parse(result);
      that.setState({
        dataSource: jsonData["details"],
        buildName: jsonData["BuildName"],
        productName: jsonData["ProductName"],
        branchName: jsonData["BranchName"],
        testDate: jsonData["TestDate"],
        totalCount: jsonData["totalCount"],
        currentRunId: jsonData["SummaryId"],
        Loading: false
      });
    })
  };
  render() {
    return (
      <div>
        <div id="main" style={{ width: 1000, height: 400 }} >
          <Icon type="line-chart" />
        </div>
        <div style={{ background: '#ECECEC', padding: '30px' }}>
          <Row gutter={16}>
            <Col span={6}>
              <Card title="Product Name" bordered={false}>
                {this.state.productName}
              </Card>
            </Col>
            <Col span={6}>
              <Card title="Build" bordered={false}>
                {this.state.buildName}
              </Card>
            </Col>
            <Col span={6}>
              <Card title="Branch" bordered={false}>
                {this.state.branchName}
              </Card>
            </Col>
            <Col span={6}>
              <Card title="Date" bordered={false}>
                {this.state.testDate}
              </Card>
            </Col>
          </Row>
        </div>
        <Table loading={this.state.Loading} dataSource={this.state.dataSource} columns={columns} pagination={false} />
        <Pagination className="tb" hideOnSinglePage={false} showSizeChanger onChange={this.onChange} current={this.state.current} onShowSizeChange={this.onShowSizeChange} defaultCurrent={this.state.defaultPageSize} total={this.state.totalCount} />
      </div>

    )
  }
}