import React, { Component } from 'react';
import DevBuildList from '../components/DevBuildList';
import BasicLayout from '../Layout/BasicLayout';

export default class Tree extends Component {
  render() {
    return (
        <BasicLayout>
          <DevBuildList />
        </BasicLayout>
    )
  }
}