import React, { Component } from 'react';
import BasicLayout from '../Layout/BasicLayout';
import TaskTable from '../components/TaskTable';

export default class Home extends Component {
  render() {
    return (
        <BasicLayout>
          <TaskTable></TaskTable>
        </BasicLayout>
    )
  }
}