import "../css/process.css";
import { Form, Button, Icon, Input, Select, Row, Col, Card } from 'antd';
import React from 'react';
import '../config';
// 引入 ECharts 主模块
import echarts from 'echarts/lib/echarts';
import ReactEcharts from 'echarts-for-react';

const { Option } = Select;
export default class Processess extends React.Component {
    timer = null;
    constructor(props) {
        super(props);
        this.state = {
            varsions: [],
            currentVersion: "",
            currentType: 2,
            product: "",
            build: "",
            branch: "",
            date: "",
            runIds: [],

            series: [],
            xAxisData: [],
            legend: [],

            extiscrash: true,
            ideiscrash: true,
            sysiscrash: true,
        };
        this.componentDidMount = this.componentDidMount.bind(this);
    };
    onGenderChange(value) {
        if (value == undefined) {
            this.setState({ currentVersion: "" });
        }
        else {
            this.setState({ currentVersion: value });
        }
    };
    getBranchInfo(id) {
        fetch(global.config.baseUrl + '/api/TestPortalWeb/GetBranchInfo' + "?" + "id=" + id, {
            method: 'GET',
        }).
            then(response => response.json()).
            then(res => {
                var jsonData = res.result;
                this.setState({
                    product: jsonData.product,
                    build: jsonData.build,
                    branch: jsonData.branch,
                    date: jsonData.currentTime,
                })
            })
    };
    initEChart(chart_type) {
        var that = this;
        that.changeState();
        that.setState({ currentType: chart_type })
        // 基于准备好的dom，初始化echarts实例
        const myChart = echarts.init(document.getElementById('main'));
        var headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        myChart.showLoading({
            text: '数据加载中...',
            color: '#c23531',
            background:'#333',
            textColor: '#000',
            effect:'ring',
            maskColor: 'rgba(93, 91, 91, 0.5)',
            zlevel: 0,
          });;
        fetch(global.config.baseUrl + '/api/TestPortalWeb/GetLonghaulStatusForEChart' + '?status=' + chart_type + '&version=' + that.state.currentVersion, {
            method: 'POST',
            headers: headers
        }).then(response => response.json()).
            then(res => {
                var jsonData = res.result;
                var seriesData = [];
                for (var i in jsonData.seriesDatas) {
                    var serie = {
                        name: jsonData.seriesDatas[i].name,
                        type: 'line',
                        data: jsonData.seriesDatas[i].data
                    }
                    seriesData.push(serie);
                }
                var xAxisData = [];
                for (var i in jsonData.xAxisData.data) {
                    xAxisData.push(jsonData.xAxisData.data[i].node);
                }
                var index = seriesData[0].data.length;
                var lastNum = seriesData[0].data[index - 1];
                if (lastNum == 1) {
                    that.setState({
                        isCrash: false
                    })
                }
                else {
                    that.setState({
                        isCrash: true
                    })
                }
                console.log(index);
                console.log(that.state.isCrash);
                that.setState({
                    xAxisData: xAxisData,
                    series: seriesData,
                    legend: jsonData.legendData
                })
                var runIdData = jsonData.xAxisData.data;
                
                // 绘制图表
                myChart.setOption({
                    title: {
                        text: 'Long Haul Status'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: this.state.legend
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        data: this.state.xAxisData
                    },
                    yAxis: {
                        type: 'value',
                        boundaryGap: false,
                        axisPointer: {
                            show: false
                        },
                        axisLabel: {
                            show: true,
                            interval: 'auto',
                        },
                        show: true
                    },
                    dataZoom: [{
                        type: 'inside',
                        // start: 0,
                        // end: 10
                    }, {
                        start: 0,
                        end: 10,
                        handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                        handleSize: '100%',
                        handleStyle: {
                            color: '#fff',
                            shadowBlur: 3,
                            shadowColor: 'rgba(0, 0, 0, 0.6)',
                            shadowOffsetX: 2,
                            shadowOffsetY: 2
                        }
                    }],
                    series: this.state.series
                }, true);
                myChart.hideLoading();
                //防止重复触发点击事件
                if (myChart._$handlers.click) {
                    myChart._$handlers.click.length = 0;
                }
                myChart.on('click', function (params) {
                    that.setState({
                        Loading: true
                    });
                    let xname = params.name;
                    let serieName = params.seriesName;
                    let value = params.value;
                    let runId = "";
                    for (var i in runIdData) {
                        if (runIdData[i].node === xname) {
                            runId = runIdData[i].runId[serieName];
                            that.setState({
                                currentRunId: runId
                            });
                            that.getBranchInfo(runId);
                            break;
                        }
                    }
                })
            });
        // myChart.resize();
    };
    componentDidMount = () => {
        var that = this;
        that.getversions();
        that.initEChart(that.state.currentType);
        that.getBranchInfo("");
        // 组件加载完毕 启动定时器
        setTimeout(this.iTimer, 0);
    };
    // 定时器
    iTimer = () => {
        this.timer = setInterval(() => {
            console.log("data refresh！");
            this.initEChart(this.state.currentType, "");
        }, 1000 * 60 * 5);
    };
    // 组件清除时清除定时器
    componentWillUnmount() {
        clearInterval(this.timer);
    };
    getversions() {
        var that = this;
        fetch(global.config.baseUrl + '/api/TestPortalWeb/GetBuilds', {
            method: 'get',
        }).
            then(response => response.json()).
            then(res => {
                var jsonData = res.result;
                that.setState({
                    varsions: jsonData,
                })
            })
    };
    changeState=()=>{
        fetch(global.config.baseUrl + '/api/TestPortalWeb/GetStatus', {
            method: 'get',
        }).
            then(response => response.json()).
            then(res => {
                var jsonData = res.result;
                console.log(jsonData);
                this.setState({
                    extiscrash: jsonData.executorIsCrash,
                    ideiscrash: jsonData.ideIsCrash,
                    sysiscrash: jsonData.systemIsCrash,
                })
            })
    }
    render() {
        const extbackground={background:this.state.extiscrash==false?"red":"#8dc7fd"};
        const idebackground={background:this.state.ideiscrash==false?"red":"#8dc7fd"};
        const sysbackground={background:this.state.sysiscrash==false?"red":"#8dc7fd"};
        return (
            <div>
                <div style={{ marginBottom: 20 }}>
                    <Form.Item
                        name="select"
                        hasFeedback
                        rules={[{ required: true, message: '请选择版本号！' }]}
                    >
                        <Select placeholder="请选择版本号！" style={{ width: 360 }} allowClear onChange={this.onGenderChange.bind(this)}>
                            {this.state.varsions.map(it => (
                                <Select.Option key={it} value={it}>
                                    {it}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Card bordered={false} hoverable={true} bodyStyle={{ padding: 0 }}>
                                <div className="cardNav" style={extbackground} onClick={() => this.initEChart(2, "")}>
                                    <span className="cardBody">Exerutor</span>
                                </div>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card bordered={false} hoverable={true} bodyStyle={{ padding: 0 }}>
                                <div className="cardNav" style={idebackground} onClick={() => this.initEChart(1, "")}>
                                    <span className="cardBody">IDE</span>
                                </div>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card bordered={false} hoverable={true} bodyStyle={{ padding: 0 }}>
                                <div className="cardNav" style={sysbackground} onClick={() => this.initEChart(0, "")}>
                                    <span className="cardBody">System</span>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div style={{ marginBottom: 20 }}>
                    <div id="main" className="echartsize">
                        {/* <Icon type="line-chart" /> */}
                    </div>
                </div>
                <div style={{ background: '#ECECEC', padding: '30px' }}>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Card title="Product Name" bordered={false}>
                                {this.state.product}
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card title="Build" bordered={false}>
                                {this.state.build}
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card title="Branch" bordered={false}>
                                {this.state.branch}
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card title="Date" bordered={false}>
                                {this.state.date}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

        );
    }
}