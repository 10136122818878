import React, { Component } from 'react';
import Process from '../components/Processes';
import BasicLayout from '../Layout/BasicLayout';

export default class LongHaulStatus extends Component {
  render() {
    return (
        <BasicLayout>
          <Process />
        </BasicLayout>
    )
  }
}