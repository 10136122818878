//引入react jsx写法的必须
import React from 'react';
//引入需要用到的页面组件 
import Home from './page/home';
import Process from './page/process';
import Tree from './page/tree';
//引入一些模块
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function router() {
    return (
        <Router>
             <Route exact path="/" component={Home} />
             <Route strict path="/tree" component={Tree} /> 
             <Route strict path="/process" component={Process} />           
        </Router>);
}
export default router;