import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import logo from './logo.svg';
import BRouter from './router'
// import './App.css';

function App() {
  return (
          <BRouter />
  );
}

export default App;